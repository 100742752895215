import * as React from 'react';
import _ from 'lodash';
import { Col, Container, Row } from 'reactstrap';
import faqsData from '../data/faqs.json';
import { BaseLayout, FaqEntry } from '../components';

export const FaqsPageInternal = ({ faqs }: typeof faqsData) => {
  return (
    <BaseLayout title="News and Info">
      <Container>
        <Row>
          <Col md={12}>
            {
              _.map(faqs, (faq, i) => {
                const isLast = i === _.size(faqs) - 1;

                return (
                  <React.Fragment key={i}>
                    <FaqEntry {...faq} defaultExpanded={false} />

                    {!isLast && <hr />}
                  </React.Fragment>
                );
              })
            }
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
};

export default () => <FaqsPageInternal {...faqsData} />;
